import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from 'ReusableComponents/Box';
import Button from 'ReusableComponents/Button';
import Up from 'assets/Up.svg';
import Down from 'assets/Down.svg';
import Ad from 'assets/images/Ad.svg';
import Text from 'ReusableComponents/Text';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMedia } from 'helpers';
import { useHistory } from 'react-router-dom';
import StatusComponent from './StatusComponent';
import './style.css';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';

function AdTray() {
  const isSmall = useMedia('(max-width:450px)');
  const authToken = useSelector((state) => state.auth?.token);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [transactionAmount, setTransactionAmount] = useState(65);
  const [closeTime, setCloseTime] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [triggerTimer, setTriggerTimer] = useState(true);
  const time = () => moment().startOf('day').seconds(seconds).format('mm:ss');
  const handleOpen = () => {
    if (open) {
      setTriggerTimer(true);
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (closeTime && open) {
      setTimeout(() => setCloseTime(false), 3000);
    }
    if (open) {
      setSeconds(30);
    }
    if (!open) {
      setCloseTime(true);
      setSeconds(30);
    }
  }, [closeTime, open]);

  useEffect(() => {
    if (triggerTimer) {
      let t = seconds;
      const timer = setInterval(() => {
        if (t === 0 || seconds === -1) {
          setOpen(true);
          setSeconds(2);
          setTriggerTimer(false);
          clearTimeout(timer);
        }
        t -= 1;
        setSeconds(t);
      }, 1000);
    }
  }, [triggerTimer]);

  return (
    <>
      {!authToken ? (
        <Box
          background={DESIGN_CONFIG.color.neutral.neutral500}
          boxShadow={DESIGN_CONFIG.color.common.boxShadow}
          bottom={0}
          h="auto"
          left="50%"
          padding={isSmall ? '1rem' : '1rem 1.5rem'}
          position="fixed"
          transform="translateX(-50%)"
          w={isSmall ? '375px' : '776px'}
          zIndex="12"
        >
          {!open ? <div className="progress-bar" style={{ width: `${(seconds / 30) * 100}%` }} /> : null}
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            position="relative"
            w="100%"
          >
            {!open && (
              <Text
                fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
                fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
                marginBottom={0}
                marginRight="0.625rem"
                textColor={DESIGN_CONFIG.color.text.text100}
              >
                {STRING_CONFIG.inPage.adTray.txtNextAdIn}
                {' '}
                {time()}
              </Text>
            )}
            {open && closeTime ? (
              <CircularProgress
                style={{
                  color: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                  height: 16,
                  marginBottom: 10,
                  marginLeft: 'auto',
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                  width: 16,
                }}
              />
            ) : (
              <img
                alt=""
                onClick={handleOpen}
                src={open ? Down : Up}
                style={{
                  cursor: 'pointer',
                  marginBottom: 10,
                  marginLeft: `${isSmall ? '.5rem' : 'auto'}`,
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                }}
              />
            )}
          </Box>
          {open && (
            <Box
              style={{ transition: 'all 1s ease-out' }}
              className="animate__animated animate__slideInUp"
            >
              <Box display="flex" h={isSmall ? '170px' : '90px'} justifyContent="center" w="100%">
                <img alt={Ad} height="100%" src={isSmall ? '/assets/adSmall.svg' : '/assets/google-ad.png'} width="100%" />
              </Box>
              <Box
                b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                borderRadius="6px"
                marginTop={isSmall ? '.75rem' : '1rem'}
                padding={isSmall ? '.75rem' : '1rem'}
              >
                <Box>
                  <Text
                    fontSize={isSmall ? '1rem' : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`}
                    fontWeight={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}
                    lineHeight={isSmall && '1.226rem'}
                    marginBottom={isSmall ? '.5rem' : '0.75rem'}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPageTitle}
                  </Text>
                </Box>
                <Box w="100%">
                  <Text
                    fontSize={isSmall ? '.75rem' : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`}
                    fontWeight={isSmall ? 400 : DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}
                    lineHeight={isSmall && '.875rem'}
                    marginBottom=".5rem"
                    textColor={DESIGN_CONFIG.color.text.text100}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPageSubtext}
                  </Text>
                </Box>
                <Button
                  border="none"
                  borderRadius="6px"
                  background={DESIGN_CONFIG.color.primary.primaryMain}
                  textColor={DESIGN_CONFIG.color.neutral.neutral500}
                  fontSize={DESIGN_CONFIG.typography.common.webButton.fontSize}
                  fontWeight={DESIGN_CONFIG.typography.common.webButton.fontWeight}
                  h="2.75rem"
                  onClick={() => history.push('/sign-in')}
                  padding=".75rem"
                  w="100%"
                >
                  {STRING_CONFIG.inPage.common.btnTryAdFree}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <StatusComponent
          closeTime={closeTime}
          handleOpen={handleOpen}
          open={open}
          seconds={seconds}
          setTransactionAmount={setTransactionAmount}
          time={time}
          transactionAmount={transactionAmount}
        />
      )}
    </>
  );
}

export default AdTray;
