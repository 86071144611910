import React from 'react';

function AppGrid({ fill = '#011726' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33325 6.66658H6.66658V3.33325H3.33325V6.66658ZM8.33325 16.6666H11.6666V13.3333H8.33325V16.6666ZM3.33325 16.6666H6.66658V13.3333H3.33325V16.6666ZM3.33325 11.6666H6.66658V8.33325H3.33325V11.6666ZM8.33325 11.6666H11.6666V8.33325H8.33325V11.6666ZM13.3333 3.33325V6.66658H16.6666V3.33325H13.3333ZM8.33325 6.66658H11.6666V3.33325H8.33325V6.66658ZM13.3333 11.6666H16.6666V8.33325H13.3333V11.6666ZM13.3333 16.6666H16.6666V13.3333H13.3333V16.6666Z"
        fill={fill}
        fillOpacity="0.3"
      />
    </svg>
  );
}

export default AppGrid;
