/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6,
} from './style';

export function H1({ children, ...props }) {
  return (
    <StyledH1 {...props}>
      {children}
    </StyledH1>
  );
}

export function H2({ children, ...props }) {
  return (
    <StyledH2 {...props}>
      {children}
    </StyledH2>
  );
}

export function H3({ children, ...props }) {
  return (
    <StyledH3 {...props}>
      {children}
    </StyledH3>
  );
}
export function H4({ children, ...props }) {
  return (
    <StyledH4 {...props}>
      {children}
    </StyledH4>
  );
}
export function H5({ children, ...props }) {
  return (
    <StyledH5 {...props}>
      {children}
    </StyledH5>
  );
}
export function H6({ children, ...props }) {
  return (
    <StyledH6 {...props}>
      {children}
    </StyledH6>
  );
}
