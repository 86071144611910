import React, { useEffect, useState } from 'react';
import Signup from 'assets/Signup.svg';
import Badge from 'assets/Badge.svg';
import Button from 'ReusableComponents/Button';
import Text from 'ReusableComponents/Text';
import Box from 'ReusableComponents/Box';
import defaultProfile from 'assets/default_profile.png';
import { activeIndex } from 'store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StyledLink from 'ReusableComponents/Link';
import Dropdown from './Dropdown';
import './style.css';
import { articleData } from 'Pages/Articles/data';
import { DESIGN_CONFIG } from 'utils';

function Sidebar() {
  const authToken = useSelector(({ auth }) => auth.token);
  const index = useSelector(({ auth }) => auth.activeIndex);
  const activeArticleId = useSelector((state) => state.platform.activeArticleId || 1);
  const user = useSelector(({ auth }) => auth.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const allTitles = Object.values(articleData).map((article) => article.title);
    setHeadings(allTitles);
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/') dispatch(activeIndex(1));
    else if (history.location.pathname === '/browse') dispatch(activeIndex(2));
    else if (history.location.pathname === '/articles') dispatch(activeIndex(3));
    else {
      dispatch(activeIndex(-1));
    }
  }, [history]);

  const handleChangeMenu = (i) => {
    dispatch(activeIndex(i));
  };

  const handleSignIn = () => {
    history.push('/sign-in');
  };

  return (
    <Box
      background={DESIGN_CONFIG.color.neutral.neutral500}
      display="flex"
      flexDirection="column"
      marginTop=".75rem"
    >
      <ul className="hamburger-menu-list" role="menubar">
        <li
          onClick={() => {
            handleChangeMenu(1);
            history.push('/');
          }}
          role="none"
        >
          <StyledLink
            color={index === 1 ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}
            fontSize=".75rem"
            fontWeight={400}
            hoverColor={DESIGN_CONFIG.color.primary.primaryMain}
            role="menuitem"
            to="/"
          >
            Home
          </StyledLink>
        </li>
        <li
          onClick={() => {
            handleChangeMenu(2);
            history.push('/browse');
          }}
          role="none"
        >
          <StyledLink
            color={index === 2 ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}
            fontSize=".75rem"
            fontWeight={400}
            hoverColor={DESIGN_CONFIG.color.primary.primaryMain}
            role="menuitem"
            to="/browse"
          >
            Browse
          </StyledLink>
        </li>
        <li>
          <Dropdown
            index={index}
            activeArticleId={activeArticleId}
            options={headings}
          />
        </li>
      </ul>

      {authToken ? (
        <Box
          bottom=".938rem"
          cursor="pointer"
          display="flex"
          left="17%"
          margin=".625rem 1.25rem"
          position="absolute"
        >
          <img
            alt="user image"
            height={50}
            src={user?.image || defaultProfile}
            style={{ marginRight: 12, borderRadius: '6px' }}
            width={50}
          />
          <Box display="flex" flexDirection="column">
            <Text
              fontSize=".875rem"
              fontWeight={500}
              margin="0"
            >
              {user.firstName}
              {' '}
              {user.lastName}
            </Text>
            <Box display="flex">
              <img
                alt=""
                height=".938rem"
                src={Badge}
                style={{ marginTop: 4, marginRight: 8 }}
              />
              <Text
                fontSize=".875rem"
                fontWeight={500}
                margin="0"
                textColor={DESIGN_CONFIG.color.text.text200}
              >
                Reviewer
              </Text>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          alignItems="center"
          bottom="1.5rem"
          display="flex"
          justifyContent="center"
          onClick={handleSignIn}
          position="fixed"
          w="100%"
        >
          <Button
            alignItems="center"
            cursor="pointer"
            background={DESIGN_CONFIG.color.neutral.neutral500}
            border={`1px solid ${DESIGN_CONFIG.color.primary.primaryMain}`}
            borderRadius="6px"
            display="flex"
            h="48px"
            justifyContent="center"
          >
            <img alt="" src={Signup} style={{ marginRight: '.625rem' }} />
            <Text
              fontSize=".875rem"
              fontWeight={500}
              marginBottom="0px"
              textColor={DESIGN_CONFIG.color.primary.primaryMain}
            >
              Sign In
            </Text>
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Sidebar;
