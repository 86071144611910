import React from 'react';
import { DESIGN_CONFIG } from 'utils';
import { InputStyled } from './styles';

function Input({
  children, isIcon, error, isSemiBold, ...props
}) {
  return (
    <InputStyled
      border={`1px solid ${error ? DESIGN_CONFIG.color.error.errorMain : DESIGN_CONFIG.color.neutral.neutral100
      }`}
      data-testid="input"
      fw={isSemiBold && '700'}
      outline="none"
      {...props}

      type="text"
    />
  );
}

export default Input;
