import React, { useRef, useState, useEffect } from 'react';
import Box from 'ReusableComponents/Box';
import RatingBar from 'ReusableComponents/RatingBar';
import { useMedia } from 'helpers';
import rdLogo from 'assets/Logos/rdLogo.svg';
import { Helmet } from 'react-helmet';
import UserCampaignSliderBottom from 'Pages/Components/Sliders/UserCampaignBottom';
import FooterUserCampaign from 'Pages/Footer/FooterUserCampaign';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UserCampaignSlider from 'Pages/Components/Sliders/UserCampaign';
import './style.css';
import Text from 'ReusableComponents/Text';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import LaunchingSoon from './Modal';

function UserCampaign() {
  const [show, setShow] = useState(false);
  const [ratings, setRating] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [hasUserSubmitted, setUserSubmittedStatus] = useState(false);
  const smallDevice = useMedia('(max-width:450px)');
  const MQ730 = useMedia('(max-width:730px)');
  const MQ640 = useMedia('(max-width:640px)');
  const MQ450 = useMedia('(max-width:450px)');
  const openerButtonRef = useRef(null);
  useEffect(() => {
    const feedbackRatings = localStorage.getItem('rd-feedback-rating');
    if (feedbackRatings) {
      setUserSubmittedStatus(true);
      setRating(feedbackRatings);
    }
  }, []);

  const handleChange = (newValue) => {
    setInputValue(newValue);
  };
  return (
    <>
      <div className="navigation-bar">
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: '.75rem',
          }}
        >
          <img
            alt="Reviewdale"
            height={MQ640 ? '36px' : MQ730 ? '40px' : '48px'}
            src={rdLogo}
            width={MQ640 ? '36px' : MQ730 ? '40px' : '48px'}
          />
          <Box>
            <p style={{
              margin: 0,
              fontSize: `${DESIGN_CONFIG.typography.largeCards.webLgCardTitle.fontSize}`,
              fontWeight: DESIGN_CONFIG.typography.largeCards.webLgCardTitle.fontWeight,
              color: `${DESIGN_CONFIG.color.text.text200}`,
            }}
            >
              ReviewDale
            </p>
            <p style={{
              color: '#7A7A7A',
              fontSize: `${DESIGN_CONFIG.typography.largeCards.webLgCardSubtitle.fontSize}`,
              fontWeight: DESIGN_CONFIG.typography.largeCards.webLgCardSubtitle.fontWeight,
              margin: 0,
            }}
            >
              Your Product Community
            </p>
          </Box>
        </Box>
      </div>
      <Text
        alignItems="center"
        background={`linear-gradient(to right, ${DESIGN_CONFIG.color.primary.primaryMain} , #7B61FF)`}
        display="flex"
        fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
        fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
        justifyContent="center"
        margin={0}
        padding="1rem 0"
        textAlign="center"
        textColor={DESIGN_CONFIG.color.neutral.neutral500}
      >
        Mark your calendars! We are coming to your browser on September 2nd, 2024! 🚀
      </Text>
      <div className="top-page-container">
        {show ? (
          <LaunchingSoon
            inputValue={inputValue}
            openerButtonRef={openerButtonRef}
            setRating={setRating}
            setShow={setShow}
            setUserSubmittedStatus={setUserSubmittedStatus}
            show={show}
          />
        ) : null}
        <main className="pre-mvp">
          <section className="pre-mvp__section pre-mvp__section--1">
            <div className="pre-mvp__section--1--left">
              <h1>
                Home of your current and future phones
              </h1>
              <p>
                Share your phone experience and help others find the right fit.
              </p>
              {
                hasUserSubmitted ? (
                  <div className="pre-mvp__user-feedback-card">
                    <h2 className="heading">
                      Thanks for sharing your thoughts!
                    </h2>
                    <p className="paragraph">Your feedback will help us make ReviewDale better</p>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '.5rem',
                      }}
                      >
                        <img src="/assets/rdLogo.svg" alt="" width={20} height={20} />
                        <p style={{
                          color: `${DESIGN_CONFIG.color.text.text500}`,
                          fontSize: '1.125rem',
                          fontWeight: 600,
                          marginBottom: '0',
                        }}
                        >
                          ReviewDale
                        </p>
                      </div>
                      <p style={{
                        border: `1px solid ${(ratings >= 0 && ratings < 2) ? DESIGN_CONFIG.color.error.errorMain
                          : (ratings >= 2 && ratings < 3) ? DESIGN_CONFIG.color.warning.warningMain
                            : (ratings >= 3 && ratings < 4) ? DESIGN_CONFIG.color.success.successMain
                              : (ratings >= 4 && ratings < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                                : null
                          }`,
                        borderRadius: '40px',
                        background: `${(ratings >= 0 && ratings < 2) ? DESIGN_CONFIG.color.error.error50
                          : (ratings >= 2 && ratings < 3) ? DESIGN_CONFIG.color.warning.warning50
                            : (ratings >= 3 && ratings < 4) ? DESIGN_CONFIG.color.success.success50
                              : (ratings >= 4 && ratings < 5) ? DESIGN_CONFIG.color.primary.primary50
                                : null}`,
                        color: `${(ratings >= 0 && ratings < 2) ? DESIGN_CONFIG.color.error.errorMain
                          : (ratings >= 2 && ratings < 3) ? DESIGN_CONFIG.color.warning.warningMain
                            : (ratings >= 3 && ratings < 4) ? DESIGN_CONFIG.color.success.successMain
                              : (ratings >= 4 && ratings < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                                : null}`,
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        margin: '0',
                        padding: '.5rem 1.5rem',
                      }}
                      >
                        {(ratings >= 0 && ratings < 2) ? STRING_CONFIG.entityCaption.review.ratingSegment.one
                          : (ratings >= 2 && ratings < 3) ? STRING_CONFIG.entityCaption.review.ratingSegment.two
                            : (ratings >= 3 && ratings < 4) ? STRING_CONFIG.entityCaption.review.ratingSegment.three
                              : (ratings >= 4 && ratings < 5) ? STRING_CONFIG.entityCaption.review.ratingSegment.four
                                : (ratings == 5) ? STRING_CONFIG.entityCaption.review.ratingSegment.five : ''}
                      </p>
                    </Box>
                    <div className="pre-mvp__range">
                      <RatingBar width="100%" rating={ratings} />
                    </div>
                    <div style={{
                      alignItems: 'center',
                      color: `${DESIGN_CONFIG.color.text.text200}`,
                      display: 'flex',
                      fontSize: '1.125rem',
                      fontWeight: 400,
                      justifyContent: 'space-between',
                    }}
                    >
                      <span style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}>Bad</span>
                      <span style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}>Good</span>
                    </div>
                  </div>
                ) : (
                  <div className="pre-mvp__user-feedback-card">
                    <h2 className="heading">
                      Help us by rating
                      {' '}
                      <i style={{ fontSize: 'inherit' }}>ReviewDale</i>
                    </h2>
                    <p className="paragraph">How helpful would a Reviewing platform for phones be for you?</p>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '.5rem',
                      }}
                      >
                        <img src="/assets/rdLogo.svg" alt="" width={20} height={20} />
                        <p style={{
                          color: `${DESIGN_CONFIG.color.text.text500}`,
                          fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                          fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                          marginBottom: '0',
                        }}
                        >
                          ReviewDale
                        </p>
                      </div>
                      <p style={{
                        border: `1px solid ${(inputValue > 0 && inputValue < 2) ? DESIGN_CONFIG.color.error.errorMain
                          : (inputValue >= 2 && inputValue < 3) ? DESIGN_CONFIG.color.warning.warningMain
                            : (inputValue >= 3 && inputValue < 4) ? DESIGN_CONFIG.color.success.successMain
                              : (inputValue >= 4 && inputValue < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                                : DESIGN_CONFIG.color.primary.primaryMain
                          }`,
                        borderRadius: '40px',
                        background: `${(inputValue > 0 && inputValue < 2) ? DESIGN_CONFIG.color.error.error50
                          : (inputValue >= 2 && inputValue < 3) ? DESIGN_CONFIG.color.warning.warning50
                            : (inputValue >= 3 && inputValue < 4) ? DESIGN_CONFIG.color.success.success50
                              : (inputValue >= 4 && inputValue < 5) ? DESIGN_CONFIG.color.primary.primary50
                                : DESIGN_CONFIG.color.primary.primary50
                          }`,
                        color: `${(inputValue > 0 && inputValue < 2) ? DESIGN_CONFIG.color.error.errorMain
                          : (inputValue >= 2 && inputValue < 3) ? DESIGN_CONFIG.color.warning.warningMain
                            : (inputValue >= 3 && inputValue < 4) ? DESIGN_CONFIG.color.success.successMain
                              : (inputValue >= 4 && inputValue < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                                : DESIGN_CONFIG.color.primary.primaryMain
                          }`,
                        fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                        fontWeight: DESIGN_CONFIG.typography.common.webTag.fontWeight,
                        margin: '0',
                        padding: '.5rem 1.5rem',
                      }}
                      >
                        {(inputValue >= 0 && inputValue < 2) ? STRING_CONFIG.entityCaption.review.ratingSegment.one
                          : (inputValue >= 2 && inputValue < 3) ? STRING_CONFIG.entityCaption.review.ratingSegment.two
                            : (inputValue >= 3 && inputValue < 4) ? STRING_CONFIG.entityCaption.review.ratingSegment.three
                              : (inputValue >= 4 && inputValue < 5) ? STRING_CONFIG.entityCaption.review.ratingSegment.four
                                : (inputValue == 5) ? STRING_CONFIG.entityCaption.review.ratingSegment.five : ''}
                      </p>
                    </Box>
                    <div className="pre-mvp__range">
                      <UserCampaignSlider onChange={handleChange} />
                    </div>
                    <div style={{
                      alignItems: 'center',
                      color: `${DESIGN_CONFIG.color.text.text200}`,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    >
                      <span style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}>Bad</span>
                      <span style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}>Good</span>
                    </div>
                    <div className="pre-mvp_button">
                      <button style={{ width: `${MQ450 && '100%'}` }} ref={openerButtonRef} onClick={() => setShow(!show)} type="button">Submit</button>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="pre-mvp__section--1--right">
              <LazyLoadImage
                alt="banner image"
                effect="blur"
                height="100%"
                src="/assets/banner.svg"
                width="100%"
              />
            </div>
          </section>
          <section className="pre-mvp__section pre-mvp__section--2">
            <div>
              <LazyLoadImage
                alt="introducing reviewdale"
                effect="blur"
                height="100%"
                src="/assets/Introducing.svg"
                width="100%"
              />
            </div>
            <div>
              <h2 className="pre-mvp__heading">
                Introducing ReviewDale
              </h2>
              <p className="pre-mvp__paragraph">
                ReviewDale helps you choose. Find honest reviews, personalized picks, and clear comparisons.
                {' '}
                <i>Dive in!</i>
              </p>
            </div>
          </section>
          <section className="pre-mvp__section pre-mvp__section--3">
            <div>
              <h2 className="pre-mvp__heading">
                Before you buy a Phone, visit ReviewDale
              </h2>
              <p className="pre-mvp__paragraph">
                More than 70% internet users read online reviews before finding their perfect phone.
                ReviewDale is a community of passionate people like you,
                who are looking for the right phone.
              </p>
            </div>
            <div>
              <LazyLoadImage
                alt="Visit ReviewDale"
                effect="blur"
                height="100%"
                src="/assets/VisitReviewDale.svg"
                width="100%"
              />
            </div>
          </section>
          <section className="pre-mvp__section--4">
            <h2 className="pre-mvp__heading" style={{ textAlign: 'center' }}>
              Find meaningful Reviews
            </h2>
            <p className="pre-mvp__paragraph" style={{ maxWidth: '50.25rem', margin: 'auto', textAlign: 'center' }}>
              ReviewDale is a unified experience to discover meaningful phone reviews.
              Our reviews are structured to give you information that matters.
              It&rsquo;s like knowing the phone even before owning it!
            </p>
            <div style={{
              display: 'flex', gap: '2.5rem', justifyContent: 'space-between', marginTop: `${MQ450 ? '1rem' : ''}`,
            }}
            >
              <div style={{
                height: '15.625rem',
                width: '12.5rem',
              }}
              >
                <LazyLoadImage
                  alt="Iphone 13"
                  effect="blur"
                  height="100%"
                  src={MQ450 ? '/assets/samsungGalaxySmall.svg' : '/assets/samsungGalaxyM14.svg'}
                  style={{ objectFit: 'contain' }}
                  width="100%"
                />
              </div>
              <div style={{
                display: `${MQ730 ? 'none' : 'block'}`,
                height: '15.625rem',
                width: '12.5rem',
              }}
              >
                <LazyLoadImage
                  alt="Iphone 13"
                  effect="blur"
                  height="100%"
                  src="/assets/iqooZ7Pro.svg"
                  style={{ objectFit: 'contain' }}
                  width="100%"
                />
              </div>
              <div style={{
                display: `${MQ730 ? 'none' : 'block'}`,
                height: '15.625rem',
                width: '12.5rem',
              }}
              >
                <LazyLoadImage
                  alt="Iphone 13"
                  effect="blur"
                  height="100%"
                  src="/assets/oneplusNordCE3.svg"
                  style={{ objectFit: 'contain' }}
                  width="100%"
                />
              </div>
              <div style={{
                height: '15.625rem',
                width: '12.5rem',
              }}
              >
                <LazyLoadImage
                  alt="redmi"
                  effect="blur"
                  height="100%"
                  src={MQ450 ? '/assets/samsungGalaxySmall.svg' : '/assets/realmeNarzo70.svg'}
                  style={{ objectFit: 'contain' }}
                  width="100%"
                />
              </div>
            </div>
          </section>
          <section className="pre-mvp__section--5">
            <h2 className="pre-mvp__heading" style={{ margin: '0 auto .5rem auto', maxWidth: '56.25rem', textAlign: 'center' }}>
              You deserve real Reviews
            </h2>
            <p className="pre-mvp__paragraph" style={{ textAlign: 'center', margin: `${MQ450 ? '0 auto 1rem auto' : '0 auto 1.5rem auto'}`, maxWidth: '56.25rem' }}>
              ReviewDale is committed to becoming the platform of choice for
              honest, meaningful and unbiased phone reviews. Our journey has
              just begun!
            </p>
            <Box display="flex" justifyContent="center" width="100%">
              <LazyLoadImage
                alt="you deserve real reviews"
                effect="blur"
                src={'/assets/userCampaignProduct.png'}
                style={{ objectFit: 'contain' }}
              />
            </Box>
          </section>
          <section className="pre-mvp__section--6">
            <h2 className="pre-mvp__heading" style={{ textAlign: 'center' }}>
              Ease of our Experience-metric Reviews
            </h2>
            <p className="pre-mvp__paragraph" style={{ textAlign: 'center', maxWidth: '56.25rem', margin: `${MQ450 ? '0 auto 1rem auto' : '0 auto 2.5rem auto'}` }}>
              We understand that your needs are unique, our Experience-metric
              based Reviews makes it faster & easier for you to decide. We keep
              researching for simpler ways for you to buy your next best phone.
            </p>
            <Box display="flex" justifyContent="center" marginBottom="1.5rem" width="100%">
              <LazyLoadImage
                alt="Ease Of Experience"
                effect="blur"
                style={{ objectFit: 'contain' }}
                src={smallDevice ? '/assets/EaseOfExperienceMetricsSmall.svg' : '/assets/userCampaignMetrics.png'}
              />
            </Box>
          </section>
          <section style={{
            display: 'flex', flexDirection: `${MQ450 ? 'column' : ''}`, gap: `${MQ450 ? '1rem' : '2.5rem'}`, justifyContent: 'space-between',
          }}
          >
            <Box
              h={MQ450 ? '100%' : '19.875rem'}
              margin={MQ450 ? '.5rem 0 0 0' : '1.5rem 0'}
              w={MQ450 ? '100%' : '25rem'}
            >
              <img src="/assets/userCampaignBottomCard.png" style={{ objectFit: 'contain' }} alt="" />
            </Box>
            {
              hasUserSubmitted ? (
                <div className="pre-mvp__user-feedback-card--bottom">
                  <h2 className="heading">
                    Thanks for sharing your thoughts!
                  </h2>
                  <p className="paragraph">Your feedback will help us make ReviewDale better</p>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <div style={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '.5rem',
                    }}
                    >
                      <img src="/assets/rdLogo.svg" alt="" width={20} height={20} />
                      <p style={{
                        color: `${DESIGN_CONFIG.color.text.text500}`,
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        marginBottom: '0',
                      }}
                      >
                        ReviewDale
                      </p>
                    </div>
                    <p style={{
                      border: `1px solid ${(ratings >= 0 && ratings < 2) ? DESIGN_CONFIG.color.error.errorMain
                        : (ratings >= 2 && ratings < 3) ? DESIGN_CONFIG.color.warning.warningMain
                          : (ratings >= 3 && ratings < 4) ? DESIGN_CONFIG.color.success.successMain
                            : (ratings >= 4 && ratings < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                              : null
                        }`,
                      borderRadius: '40px',
                      background: `${(ratings >= 0 && ratings < 2) ? DESIGN_CONFIG.color.error.error50
                        : (ratings >= 2 && ratings < 3) ? DESIGN_CONFIG.color.warning.warning50
                          : (ratings >= 3 && ratings < 4) ? DESIGN_CONFIG.color.success.success50
                            : (ratings >= 4 && ratings < 5) ? DESIGN_CONFIG.color.primary.primary50
                              : null}`,
                      color: `${(ratings >= 0 && ratings < 2) ? DESIGN_CONFIG.color.error.errorMain
                        : (ratings >= 2 && ratings < 3) ? DESIGN_CONFIG.color.warning.warningMain
                          : (ratings >= 3 && ratings < 4) ? DESIGN_CONFIG.color.success.successMain
                            : (ratings >= 4 && ratings < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                              : null}`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webTag.fontWeight,
                      margin: '0',
                      padding: '.5rem 1.5rem',
                    }}
                    >
                      {(ratings >= 0 && ratings < 2) ? 'Bad'
                        : (ratings >= 2 && ratings < 3) ? 'So so'
                          : (ratings >= 3 && ratings < 4) ? 'Nice'
                            : (ratings >= 4 && ratings < 5) ? 'Great'
                              : (ratings == 5) ? 'Excellent' : ''}
                    </p>
                  </Box>
                  <div className="pre-mvp__range">
                    <RatingBar width="100%" rating={ratings} />
                  </div>
                  <div style={{
                    alignItems: 'center',
                    color: `${DESIGN_CONFIG.color.text.text200}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  >
                    <span style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}>Bad</span>
                    <span style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}>Good</span>
                  </div>
                </div>
              ) : (
                <div className="pre-mvp__user-feedback-card--bottom">
                  <h2 className="heading">
                    Excited enough?
                  </h2>
                  <p className="paragraph">Tell us how helpful will ReviewDale be</p>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <div style={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '.5rem',
                    }}
                    >
                      <img src="/assets/rdLogo.svg" alt="" width={20} height={20} />
                      <p style={{
                        color: `${DESIGN_CONFIG.color.text.text500}`,
                        fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                        fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                        marginBottom: '0',
                      }}
                      >
                        ReviewDale
                      </p>
                    </div>
                    <p style={{
                      border: `1px solid ${(inputValue > 0 && inputValue < 2) ? DESIGN_CONFIG.color.error.errorMain
                        : (inputValue >= 2 && inputValue < 3) ? DESIGN_CONFIG.color.warning.warningMain
                          : (inputValue >= 3 && inputValue < 4) ? DESIGN_CONFIG.color.success.successMain
                            : (inputValue >= 4 && inputValue < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                              : DESIGN_CONFIG.color.primary.primaryMain
                        }`,
                      borderRadius: '40px',
                      background: `${(inputValue > 0 && inputValue < 2) ? DESIGN_CONFIG.color.error.error50
                        : (inputValue >= 2 && inputValue < 3) ? DESIGN_CONFIG.color.warning.warning50
                          : (inputValue >= 3 && inputValue < 4) ? DESIGN_CONFIG.color.success.success50
                            : (inputValue >= 4 && inputValue < 5) ? DESIGN_CONFIG.color.primary.primary50
                              : DESIGN_CONFIG.color.primary.primary50
                        }`,
                      color: `${(inputValue > 0 && inputValue < 2) ? DESIGN_CONFIG.color.error.errorMain
                        : (inputValue >= 2 && inputValue < 3) ? DESIGN_CONFIG.color.warning.warningMain
                          : (inputValue >= 3 && inputValue < 4) ? DESIGN_CONFIG.color.success.successMain
                            : (inputValue >= 4 && inputValue < 5) ? DESIGN_CONFIG.color.primary.primaryMain
                              : DESIGN_CONFIG.color.primary.primaryMain
                        }`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webTag.fontWeight,
                      margin: '0',
                      padding: '.5rem 1.5rem',
                    }}
                    >
                      {(inputValue >= 0 && inputValue < 2) ? 'Bad'
                        : (inputValue >= 2 && inputValue < 3) ? 'So so'
                          : (inputValue >= 3 && inputValue < 4) ? 'Nice'
                            : (inputValue >= 4 && inputValue < 5) ? 'Great'
                              : (inputValue == 5) ? 'Excellent' : ''}
                    </p>
                  </Box>
                  <div className="pre-mvp__range">
                    <UserCampaignSliderBottom onChange={handleChange} />
                  </div>
                  <div style={{
                    alignItems: 'center',
                    color: `${DESIGN_CONFIG.color.text.text200}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  >
                    <span style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}>Bad</span>
                    <span style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}>Good</span>
                  </div>
                  <div className="pre-mvp_button"><button style={{ width: `${MQ450 && '100%'}` }} onClick={() => setShow(true)} type="button">Submit</button></div>
                </div>
              )
            }
          </section>
        </main>
      </div>
      <FooterUserCampaign />
      <Helmet>
        <title>ReviewDale</title>
        <meta name="description" content="With the vast number of options and features available today, we understand that choosing a phone can be tricky. That's why we created ReviewDale: A Product Community, to make it easier for you and everyone to share experiences with various phones, so go ahead and indulge!" />
        <meta name="keywords" content="Product, Reviews, Genuine Reviews" />
      </Helmet>
    </>
  );
}
export default UserCampaign;
