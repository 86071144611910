import Box from 'ReusableComponents/Box';
import React, { useState } from 'react';
import TopbarSmall from 'Pages/ProductDetails/Topbar/TopbarSmall';
import TopBar from 'Pages/ProductDetails/Topbar';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'helpers';
import { Helmet } from 'react-helmet';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import Input from 'ReusableComponents/Input';
import { deleteUserAccount } from 'api/userRequests';
import { message } from 'antd';

function DeleteMyAccount() {
    const smallDevice = useMedia('(max-width:450px)');
    const [email, setEmail] = useState('');
    const history = useHistory();

    const deleteAccount = async () => {
        if (!email) {
            message.error("Please enter your email");
            return;
        }
        try {
            const { data } = await deleteUserAccount();
            if (data.success === true) {
                message.success("Account deleted successfully");
                setTimeout(() => {
                    history.push('/sign-in')
                }, 2000)
            }
        } catch (error) {
            throw error
        }
    };


    return (
        <>
            {smallDevice ? <TopbarSmall /> : <TopBar />}
            <div className="top-page-container">
                <Box
                    alignItems="center"
                    bottom={0}
                    display="flex"
                    justifyContent="center"
                    left={0}
                    position="absolute"
                    right={0}
                    top={0}
                    w="100%"
                >
                    <Box
                        b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                        borderRadius="6px"
                        display="flex"
                        flexDirection="column"
                        padding="2.5rem 1.875rem 2.188rem 2.5rem"
                        w="34.3125rem"
                    >
                        <Box>
                            <h1 style={{
                                color: `${DESIGN_CONFIG.color.text.text500}`,
                                fontSize: `${DESIGN_CONFIG.typography.largeCards.webLgCardTitle.fontSize}`,
                                fontWeight: DESIGN_CONFIG.typography.largeCards.webLgCardTitle.fontWeight,
                                margin: 0,
                            }}
                            >
                                I want to Delete my Account
                            </h1>
                            <h2 style={{
                                color: `${DESIGN_CONFIG.color.text.text200}`,
                                fontSize: `${DESIGN_CONFIG.typography.largeCards.webLgCardSubtitle.fontSize}`,
                                fontWeight: DESIGN_CONFIG.typography.largeCards.webLgCardSubtitle.fontWeight,
                                margin: '.5rem 0 0 0',
                            }}
                            >
                                Email Address
                            </h2>
                            <Input
                                background={DESIGN_CONFIG.color.neutral.neutral500}
                                border={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                                borderRadius="6px"
                                h="3rem"
                                m=".5rem 0 1rem 0"
                                onChange={(e) => setEmail(e.target.value)}
                                p=".875rem 1rem"
                                type="text"
                                value={email}
                                w="100%"
                            />
                        </Box>
                        <button
                            onClick={deleteAccount}
                            type="button"
                            style={{
                                alignSelf: 'flex-end',
                                background: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                                borderRadius: '6px',
                                border: `1px solid ${DESIGN_CONFIG.color.primary.primaryMain}`,
                                color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
                                cursor: 'pointer',
                                fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                                fontWeight: `${DESIGN_CONFIG.typography.common.webButton.fontWeight}`,
                                padding: '1rem 2rem',
                                width: '100%',
                            }}
                        >
                            Delete Account
                        </button>
                    </Box>
                </Box>
                <Helmet>
                    <title>{STRING_CONFIG.inPage.tabTitle.txtVerifyEmail}</title>
                    <meta name="description" content="With the vast number of options and features available today, we understand that choosing a phone can be tricky. That's why we created ReviewDale: A Product Community, to make it easier for you and everyone to share experiences with various phones, so go ahead and indulge!" />
                    <meta name="keywords" content="Product, Reviews, Genuine Reviews" />
                </Helmet>
            </div>
        </>
    );
}

export default DeleteMyAccount;
