/* eslint-disable */
import * as actionTypes from "./actionTypes";
import _ from "lodash";
import { BUYING_TYPES, ATTRIBUTE_TYPES } from "utils/constants";

const initialState = {
  attributes: ATTRIBUTE_TYPES,
  categories: BUYING_TYPES,
  min: 0,
  max: 100,
  selectedCategories: [],
  selectedAttributes: [],
};

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PREFERENCES: {
      const { text, border, background, name, fill } = action.payload;

      const isPresent = state.selectedCategories.includes(name);

      return {
        ...state,
        categories: state.categories.map((item) =>
          item.name === name
            ? { ...item, text, border, background, fill }
            : item
        ),
        selectedCategories: !isPresent
          ? [...state.selectedCategories, name]
          : state.selectedCategories.filter((item) => item !== name),
      };
    }

    case actionTypes.SELECT_CATEGORY: {
      const { name } = action.payload;

      const isPresent = state.selectedCategories.includes(name);

      const updateCategories = state.categories.map((item) =>
        item.name === name
          ? {
            ...item,
            text: DESIGN_CONFIG.color.primary.primaryMain,
            border: `1px solid ${DESIGN_CONFIG.color.primary.primaryMain}`,
            background: DESIGN_CONFIG.color.neutral.neutral500,
            fill: DESIGN_CONFIG.color.primary.primaryMain,
          }
          : {
            ...item,
            text: DESIGN_CONFIG.color.text.text200,
            border: "none",
            background: DESIGN_CONFIG.color.neutral.neutral50,
            fill: DESIGN_CONFIG.color.neutral.neutral200,
          }
      );

      return {
        ...state,
        categories: updateCategories,

        selectedCategories: !isPresent ? [name] : state.selectedCategories,
      };
    }

    case actionTypes.CLEAR: {
      return {
        ...state,
        selectedAttributes: [],
        attributes: ATTRIBUTE_TYPES,
        selectedCategories: [],
        categories: BUYING_TYPES,
      };
    }

    case actionTypes.SELECT_ATTRIBUTE: {
      const { name } = action.payload;

      const isPresent = state.selectedAttributes.includes(name);

      const attrCopy = state.attributes;

      for (let i = 0; i < attrCopy.length; i++) {
        if (state.selectedAttributes.length < 3) {
          if (attrCopy[i].name === name) {
            attrCopy[i].text = !isPresent
              ? DESIGN_CONFIG.color.primary.primaryMain
              : DESIGN_CONFIG.color.neutral.neutral200;
            attrCopy[i].border = !isPresent
              ? `1px solid ${DESIGN_CONFIG.color.primary.primaryMain}`
              : "none";
            attrCopy[i].background = !isPresent
              ? DESIGN_CONFIG.color.neutral.neutral500
              : DESIGN_CONFIG.color.neutral.neutral50;
            attrCopy[i].fill = !isPresent ? DESIGN_CONFIG.color.primary.primaryMain : "";
          }
        }
        if (state.selectedAttributes.length === 3 && isPresent) {
          if (attrCopy[i].name === name) {
            attrCopy[i].text = DESIGN_CONFIG.color.text.text100;
            attrCopy[i].border = "none";
            attrCopy[i].background = DESIGN_CONFIG.color.neutral.neutral50;
            attrCopy[i].fill = "";
          }
        }
      }

      const filterArr = state.selectedAttributes.filter(
        (item) => item !== name
      );

      const updatedSelectedAttributes =
        !isPresent && state.selectedAttributes.length < 3
          ? [...state.selectedAttributes, name]
          : filterArr;

      return {
        ...state,
        attributes: attrCopy,
        selectedAttributes: updatedSelectedAttributes,
      };
    }

    case actionTypes.BUDGET: {
      const { min, max } = action.payload;
      return {
        ...state,
        min,
        max,
      };
    }

    default: {
      return state;
    }
  }
};

export default attributesReducer;
