import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import auth from './auth/reducer';
import media from './media/reducer';
import preferences from './preferences/reducer';
import products from './product/reducer';
import rating from './rating/reducer';
import platform from './platform/reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  auth,
  media,
  rating,
  preferences,
  products,
  platform,
});

const middlewares = [Logger];

export const store = createStore(
  persistReducer(persistConfig, reducers),
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);
