import * as actionTypes from './actionTypes';

const initialState = {
  platformSearchInputValue: '',
  activeArticleId: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.PLATFORM_SEARCH: {
    return {
      platformSearchInputValue: action.payload,
    };
  }
  case actionTypes.CLEAR_PLATFORM_SEARCH_INPUT_VALUE: {
    return {
      platformSearchInputValue: action.payload,
    };
  }
  case actionTypes.ACTIVE_ARTICLE: {
    return {
      ...state,
      activeArticleId: action.payload,
    };
  }
  default: {
    return state;
  }
  }
};
