export const ACTIVE_INDEX = 'ACTIVE_INDEX';
export const DONATE = 'DONATE';
export const DELETE_PROFILE_IMAGE = 'DELETE_PROFILE_IMAGE';
export const SIGN_OUT = 'SIGN_OUT';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const TOKEN = 'TOKEN';
export const TRANSACTION_API_CALL = 'TRANSACTION_API_CALL';
export const USER_DETAILS = 'USER_DETAILS';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_ADTRAY_STATUS = 'UPDATE_ADTRAY_STATUS';
