import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DESIGN_CONFIG } from 'utils';

const LinkStyled = styled(Link)`
display: ${({ display }) => display};
margin: ${({ margin }) => margin};
margin-top: ${({ marginTop }) => marginTop};
margin-left: ${({ marginLeft }) => marginLeft};
padding: ${({ padding }) => padding};
line-spacing: ${({ letterSpacing }) => letterSpacing};
outline: ${({ outline }) => outline};
margin: ${({ m }) => m};
justify-content: ${({ justifyContent }) => justifyContent};
align-items: ${({ alignItems }) => alignItems};
font-size: ${({ fontSize }) => fontSize};
font-weight: ${({ fontWeight }) => fontWeight};
border-right: ${({ br }) => br};
color: ${({ color }) => color};
border: ${({ border }) => border};
border-radius: ${({ borderRadius }) => borderRadius};
transition: ${({ transition }) => transition};
&:focus {
  outline: none;
}
&:hover {
  color: ${({ hoverColor }) => hoverColor};
}
&:focus-visible {
  outline: 2px dashed ${DESIGN_CONFIG.color.primary.primaryMain};
  outline-offset: 4px
}
`;

export default LinkStyled;
