import * as actionType from './actionType';

export const isToken = (data) => ({
  type: actionType.TOKEN,
  payload: data,
});

export const activeIndex = (payload) => ({
  type: actionType.ACTIVE_INDEX,
  payload,
});

export const deleteProfileImage = () => ({
  type: actionType.DELETE_PROFILE_IMAGE,
});

export const signOut = () => ({
  type: actionType.SIGN_OUT,
});

export const showSuccess = (bolean) => ({
  type: actionType.SHOW_SUCCESS,
  payload: bolean,
});

export const transactionAPICall = (data) => ({
  type: actionType.TRANSACTION_API_CALL,
  payload: data,
});

export const updateTransaction = (data) => ({
  type: actionType.DONATE,
  payload: data,
});

export const updateAdTrayStatus = (status) => ({
  type: actionType.UPDATE_ADTRAY_STATUS,
  payload: status,
});

export const userDetails = (data) => ({
  type: actionType.USER_DETAILS,
  payload: data,
});

export const updateProfileImage = (data) => ({
  type: actionType.UPDATE_PROFILE_IMAGE,
  payload: data,
});

export const updateUsrDetails = (data) => ({
  type: actionType.UPDATE_USER_DETAILS,
  payload: data,
});
