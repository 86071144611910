import React, { forwardRef } from 'react';
import { BoxStyled } from './style';

const Box = forwardRef(({ children, ...props }, ref) => (
  <BoxStyled ref={ref} {...props} data-testid="box">
    {children}
  </BoxStyled>
));

export default Box;
