import React, { useState, useEffect } from 'react';
import Routing from 'Routes';
import InProgress from 'Pages/InProgress';
import ErrorPages from 'ReusableComponents/ErrorPages';
import ConnectionLost from 'assets/images/LostConnection.svg';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'App.css';
import { SETTING_CONFIG } from 'utils';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const [error, setError] = useState(!window.navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setError(!window.navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {error ? (
        <ErrorPages
          title="Whoops! Lost connection..."
          subtitle="The page you’re looking for isn’t available or you may have lost internet connection"
          img={ConnectionLost}
        />
      ) : (
        SETTING_CONFIG.campaign.CP001.isActive ? <InProgress /> : <Routing />
      )}
    </GoogleOAuthProvider>
  );
}

export default App;
