import styled from 'styled-components';
import { DESIGN_CONFIG } from 'utils';

export const InputStyled = styled.input`
  border-radius: ${({ borderRadius }) => borderRadius};
  border-left: ${({ borderLeft }) => borderLeft};
  border-top-right-radius: ${({ borderTopRightRadius }) => borderTopRightRadius};
  border-top-left-radius: ${({ borderTopLeftRadius }) => borderTopLeftRadius};
  border-bottom-left-radius: ${({ borderBottomLeftRadius }) => borderBottomLeftRadius};
  border-bottom-right-radius: ${({ borderBottomRightRadius }) => borderBottomRightRadius};
  margin: ${({ m }) => m};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  background-color: ${({ background = 'white' }) => background};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  outline: ${({ outline }) => outline};
  font-weight: ${({ fw }) => fw};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  max-width: ${({ mw }) => mw};
  border: ${({ border }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
  &:focus {
    border: 1px solid ${DESIGN_CONFIG.color.primary.primaryMain};
  }
  &:focus-visible {
    border: 1px solid ${DESIGN_CONFIG.color.primary.primaryMain};
  }
  &::placeholder {
    color: ${DESIGN_CONFIG.color.text.text50};
    font-size: ${DESIGN_CONFIG.typography.common.webLabel.fontSize};
    font-weight: ${DESIGN_CONFIG.typography.common.webLabel.fontWeight};
  }
`;

export const TextAreaStyled = styled.textarea`
  border-radius: ${({ borderRadius }) => borderRadius};
  border-left: ${({ borderLeft }) => borderLeft};
  border-top-right-radius: ${({ borderTopRightRadius }) => borderTopRightRadius};
  border-top-left-radius: ${({ borderTopLeftRadius }) => borderTopLeftRadius};
  border-bottom-left-radius: ${({ borderBottomLeftRadius }) => borderBottomLeftRadius};
  border-bottom-right-radius: ${({ borderBottomRightRadius }) => borderBottomRightRadius};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  background-color: ${({ background = 'white' }) => background};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  outline: ${({ outline }) => outline};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  max-width: ${({ mw }) => mw};
  border: ${({ border }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
  &:focus {
    border: 1px solid ${DESIGN_CONFIG.color.primary.primaryMain};
  }
  &:focus-visible {
    border: 1px solid ${DESIGN_CONFIG.color.primary.primaryMain};
  }
`;
