import styled from 'styled-components';

export const StyledH1 = styled.h1`
  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  user-select: ${({ userSelect }) => userSelect};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  flex: ${({ flex }) => flex};
`;
export const StyledH2 = styled.h2`
  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  user-select: ${({ userSelect }) => userSelect};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  flex: ${({ flex }) => flex};
`;
export const StyledH3 = styled.h3`
  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  user-select: ${({ userSelect }) => userSelect};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  flex: ${({ flex }) => flex};
`;
export const StyledH4 = styled.h4`
  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  user-select: ${({ userSelect }) => userSelect};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  flex: ${({ flex }) => flex};
`;
export const StyledH5 = styled.h5`
  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  user-select: ${({ userSelect }) => userSelect};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  flex: ${({ flex }) => flex};
`;
export const StyledH6 = styled.h6`
  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  user-select: ${({ userSelect }) => userSelect};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  flex: ${({ flex }) => flex};
`;
