import * as actionTypes from './actionTypes';

export const storePlatformSearchInputValue = (data) => ({
  type: actionTypes.PLATFORM_SEARCH,
  payload: data,
});
export const clearPlatformSearchInputValue = (data) => ({
  type: actionTypes.CLEAR_PLATFORM_SEARCH_INPUT_VALUE,
  payload: data,
});
export const setActiveArticle = (id) => ({
  type: actionTypes.ACTIVE_ARTICLE,
  payload: id,
});
