import React from 'react'
import Box from 'ReusableComponents/Box'
import Button from 'ReusableComponents/Button'
import Text from 'ReusableComponents/Text'
import { DESIGN_CONFIG } from 'utils'

function EmptyState({ title, subTitle, textCTA, buttonClick }) {
    return (
        <Box
            margin="auto"
            maxW="600px"
        >
            <Box
                display="flex"
                justifyContent="center"
            >
                <img
                    alt=''
                    height={300}
                    src='/assets/emptyState.svg'
                    width={300}
                />
            </Box>
            <Text
                color={DESIGN_CONFIG.color.text.text200}
                fontSize={DESIGN_CONFIG.typography.page.webPageTitle.fontSize}
                fontWeight={DESIGN_CONFIG.typography.page.webPageTitle.fontWeight}
                lineHeight={DESIGN_CONFIG.typography.page.webPageTitle.lineHeight}
                margin="1rem 0 .5rem 0"
                textAlign="center"
            >{title}
            </Text>
            <Text
                marginBottom="1.5rem"
                textAlign="center"
            >
                {subTitle}
            </Text>
            {
                textCTA ?
                    <Box display="flex"
                        justifyContent="center"
                    >
                        <Button
                            alignItems="center"
                            background={DESIGN_CONFIG.color.primary.primaryMain}
                            border="none"
                            borderRadius="6px"
                            cursor="pointer"
                            fontSize={DESIGN_CONFIG.typography.common.webButton.fontSize}
                            fontWeight={DESIGN_CONFIG.typography.common.webButton.fontWeight}
                            justifyContent="center"
                            onClick={buttonClick ? buttonClick : null}
                            padding=".75rem 1.5rem"
                            textColor={DESIGN_CONFIG.color.neutral.neutral500}
                            w="fit-content"
                        >
                            {textCTA}
                        </Button>
                    </Box>
                    : null
            }
        </Box>
    )
}

export default EmptyState