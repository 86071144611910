import React from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from 'helpers';
import Box from '../../ReusableComponents/Box';
import './style.css';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';

function FooterUserCampaign() {
  const smallDevice = useMedia('(max-width:500px)');
  const menuData = [
    {
      menuTitle: 'Contact us',
      links: [
        { title: 'reviewdale@gmail.com', url: '/', subTitle: 'Email id' },
      ],
      showSocialIcons: true,
    },
  ];
  return (
    <footer className="footer-user-campaign">
      <Box
        display="flex"
        gap=".75rem"
        margin={smallDevice ? '2.5rem 0 0 0' : '2.5rem 0 1rem 0'}
      >
        <img
          alt="Reviewdale Logo"
          height={48}
          src="/assets/rdLogoWhite.svg"
          width={48}
        />
        <Box>
          <h1
            style={{
              color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
              fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`,
              fontWeight: DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight,
              margin: '0',
            }}
          >
            {STRING_CONFIG.inPage.common.txtHeaderReviewDale}
          </h1>
          <p style={{
            color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
            fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`,
            fontWeight: DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight,
            margin: '0',
          }}
          >
            {STRING_CONFIG.inPage.common.txtHeaderReviewDaleTagline}
          </p>
        </Box>
      </Box>
      <Box
        display={smallDevice && 'flex'}
        justifyContent={smallDevice && 'space-between'}
      >
        {menuData.map((menu, index) => (
          <Box key={index}>
            <h2
              style={{
                color: `${DESIGN_CONFIG.color.primary.primary100}`,
                fontSize: `${smallDevice ? '1rem' : DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                marginTop: '1.5rem',
              }}
            >
              {menu.menuTitle}
            </h2>
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection={smallDevice && 'column'}
              gap={smallDevice ? '1rem' : '1.5rem'}
            >
              {menu.links.map((link, i) => (
                <>
                  {smallDevice && link.subTitle
                    && (
                      <h3
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: 400,
                          color: `${DESIGN_CONFIG.color.primary.primary200}`,
                          marginBottom: '0',
                        }}
                      >
                        {link.subTitle}
                      </h3>
                    )}
                  <Link
                    style={{
                      color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                    }}
                    key={i}
                    to={link.url}
                  >
                    {link.title}
                  </Link>
                </>
              ))}
              {
                menu.showSocialIcons && smallDevice ? (
                  <Box marginTop={smallDevice ? '0' : '2.5rem'}>
                    <p style={{
                      color: `${DESIGN_CONFIG.color.primary.primary100}`,
                      fontSize: '.875rem',
                      fontWeight: 400,
                      marginBottom: `${smallDevice && '.5rem'}`,
                    }}
                    >
                      {STRING_CONFIG.inPage.common.txtFooterFollow}
                    </p>
                    <Box
                      display="flex"
                      gap="1rem"
                      marginTop=".5rem"
                    >
                      <a href="https://www.facebook.com/people/ReviewDale/61557662202365/"><img src="/assets/facebook.svg" alt="facebook" /></a>
                      <a href="https://twitter.com/reviewdale"><img src="/assets/XLogo.svg" alt="X" /></a>
                      <a href="https://www.instagram.com/reviewdale_/"><img src="/icons/instagram.svg" alt="linkedin" /></a>
                      <a href="https://www.linkedin.com/company/reviewdale"><img src="/assets/linkedin.svg" alt="linkedin" /></a>
                    </Box>
                  </Box>
                ) : null
              }
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        display={smallDevice && 'none'}
        marginTop="1.25rem"
      >
        <p style={{
          color: `${DESIGN_CONFIG.color.primary.primary100}`,
          fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
          fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
        }}
        >
          {STRING_CONFIG.inPage.common.txtFooterFollow}
        </p>
        <Box display="flex" marginTop=".5rem" gap="1rem">
          <a href="https://www.facebook.com/people/ReviewDale/61557662202365/"><img src="/assets/facebook.svg" alt="facebook" /></a>
          <a href="https://twitter.com/reviewdale"><img src="/assets/XLogo.svg" alt="X" /></a>
          <a href="https://www.instagram.com/reviewdale_/"><img src="/icons/instagram.svg" alt="linkedin" /></a>
          <a href="https://www.linkedin.com/company/reviewdale"><img src="/assets/linkedin.svg" alt="linkedin" /></a>
        </Box>
      </Box>
    </footer>
  );
}

export default FooterUserCampaign;
