import React from 'react';
import { ButtonStyled } from './styles';

function Button({ children, ...props }) {
  return (
    <ButtonStyled {...props} data-testid="button" id={props?.id}>
      {children}
    </ButtonStyled>
  );
}

export default Button;
