/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Loader from 'ReusableComponents/Loader';
import * as Yup from 'yup';
import {
  Formik, Field, Form,
} from 'formik';
import { H2 } from 'ReusableComponents/Headings';
import Text from 'ReusableComponents/Text';
import { useMedia } from 'helpers';
import { userCampaign } from 'api/platformRequest';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, message } from 'antd';
import Box from 'ReusableComponents/Box';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';

const unexpectedError = (msg) => {
  Modal.error({
    content: msg,
  });
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  z-index: 100000;
  transition: all .2s ease-in;
  background: rgb(0 0 0 / 28%);
`;
const ModalContainer = styled.div`
  background: ${DESIGN_CONFIG.color.neutral.neutral500};
  border-radius: 8px;
  height: 34.375rem;
  max-width: 43.75rem;
  overflow-y: auto;
  padding: 1rem;
  width: 700px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${DESIGN_CONFIG.color.primary.primary50}; 
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${DESIGN_CONFIG.color.primary.primaryMain}; 
    border-radius: 20px; 
  }
  @media screen and (max-width: 746px) {
    ${({ smallerWidth }) => smallerWidth
    && css`
        width: 22.5rem;
      `}
  }
`;
const StyledInput = styled(Field)`
  border: 1px solid ${DESIGN_CONFIG.color.neutral.neutral100};
  border-radius: 4px;
  font-size: ${DESIGN_CONFIG.typography.common.webLabel.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webLabel.fontWeight};
  outline: none;
  padding: 0.75rem .5rem;
  transition: all 0.2s ease-in;
  width: 100%;
  &:hover,
  &:focus-visible,
  &:focus {
    border: 1px solid ${DESIGN_CONFIG.color.primary.primaryMain};
  }
  ::placeholder{
  font-size: ${DESIGN_CONFIG.typography.common.webLabel.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webLabel.fontWeight};
  }
`;
const StyledTextArea = styled(Field)`
  border: 1px solid ${DESIGN_CONFIG.color.neutral.neutral100};
  border-radius: 4px;
  font-size: ${DESIGN_CONFIG.typography.common.webLabel.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webLabel.fontWeight};
  transition: all 0.2s ease-in;
  min-height: 6.25rem;
  outline: none;
  padding: 0.75rem .5rem;
  width: 100%;
  &:hover,
  &:focus-visible,
  &:focus {
    border: 1px solid ${DESIGN_CONFIG.color.primary.primaryMain};
  }
  ::placeholder{
  font-size: ${DESIGN_CONFIG.typography.common.webLabel.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webLabel.fontWeight};
  }
`;
const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: ${({ disabled }) => (disabled ? DESIGN_CONFIG.color.common.primaryDisable : DESIGN_CONFIG.color.primary.primaryMain)};
  color: ${DESIGN_CONFIG.color.neutral.neutral500};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: block;
  flex: 1;
  font-size: ${DESIGN_CONFIG.typography.common.webButton.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webButton.fontWeight};
  outline: none;
  margin: 1rem 0 0 0;
  padding: 0.75rem 1rem; 
  transition: background-color 0.3s ease;
  &:hover:not(:disabled) {
    background-color: ${DESIGN_CONFIG.color.common.primaryHover};
  }
  &:focus-visible {
    outline: 2px solid ${DESIGN_CONFIG.color.common.primaryOutline};
    outline-offset: 2px;
  }
`;
const StyledButtonCancel = styled.button`
  border: 1px solid ${DESIGN_CONFIG.color.neutral.neutral100};
  border-radius: 4px;
  background-color: ${DESIGN_CONFIG.color.neutral.neutral500};
  color: ${DESIGN_CONFIG.color.text.text500};
  cursor: pointer;
  display: block;
  flex: 1;
  font-size: ${DESIGN_CONFIG.typography.common.webButton.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webButton.fontWeight};
  margin: 1rem 0 0 0;
  outline: none;
  &:focus-visible {
    outline: 2px solid ${DESIGN_CONFIG.color.primary.primaryMain};
    outline-offset: 2px;
  }
`;
const Label = styled.label`
  color: ${DESIGN_CONFIG.color.text.text100};
  font-size: ${DESIGN_CONFIG.typography.common.webLabel.fontSize};
  font-weight: ${DESIGN_CONFIG.typography.common.webLabel.fontWeight};
  margin-bottom: .5rem;
`;
const schema = Yup.object().shape({
  country: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  comment: Yup.string().notRequired(),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
});
export default function LaunchingSoon({
  setShow, openerButtonRef, inputValue, setUserSubmittedStatus, setRating,
}) {
  const MQ746 = useMedia('(max-width:746px)');
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    city: '',
    comment: '',
  });
  const handleSubmit = async (values, submitProp) => {
    try {
      setLoading(true);
      const userInfo = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        country: values.country,
        city: values.city,
        description: values.comment,
        rating: inputValue,
      };
      const { data } = await userCampaign(userInfo);
      if (data.success === true) {
        setLoading(false);
        submitProp.setSubmitting(false);
        submitProp.resetForm();
        localStorage.setItem('rd-feedback-rating', data.rating);
        setRating(data.rating);
        setUserSubmittedStatus(true);
        setShow(false);
        setTimeout(() => {
          message.success('Feedback submitted successfully!');
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      submitProp.resetForm();
      submitProp.setSubmitting(false);
      if (err.response) {
        history.push('/internal-server-error');
      } else if (err.request) {
        setShow(false);
        unexpectedError(STRING_CONFIG.inPage.toastMessages.txtErrorSomethingWentWrong);
      }
    }
  };
  const handleModalClose = () => {
    setShow(false);
    if (openerButtonRef.current) {
      openerButtonRef.current.focus();
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleModalClose();
      } else if (event.key === 'Tab' || (event.key === 'Shift' && event.key === 'Tab')) {
        const focusableElements = modalRef.current.querySelectorAll('button, a, input, textarea, select, [tabindex]:not([tabindex="-1"])');
        const firstFocusable = focusableElements[0];
        const lastFocusable = focusableElements[focusableElements.length - 1];

        if (event.shiftKey) {
          if (document.activeElement === firstFocusable) {
            lastFocusable.focus();
            event.preventDefault();
          }
        } else if (document.activeElement === lastFocusable) {
          firstFocusable.focus();
          event.preventDefault();
        }
      }
    };
    // Set focus on the first interactive element when modal opens
    const focusableElements = modalRef.current.querySelectorAll('button, a, input, textarea, select, [tabindex]:not([tabindex="-1"])');
    const firstFocusable = focusableElements[0];
    if (firstFocusable) {
      firstFocusable.focus();
    }
    modalRef.current.addEventListener('keydown', handleKeyDown);

    return () => {
      modalRef.current.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalRef]);

  return (
    <Overlay>
      <ModalContainer
        smallerWidth={!!MQ746}
        role="dialog"
        aria-modal="true"
        ref={modalRef}
        tabIndex={-1}
        aria-label="provide you details"
      >
        <div style={{ display: 'flex', gap: `${MQ746 ? '5px' : '5rem'}` }}>
          <div style={{ flex: 1 }}>
            <H2
              color={DESIGN_CONFIG.color.text.text500}
              fontSize={DESIGN_CONFIG.typography.page.webMainTitle.fontSize}
              fontWeight={DESIGN_CONFIG.typography.page.webMainTitle.fontWeight}
              marginBottom=".25rem"
            >
              Thanks for sharing your thoughts!
            </H2>
            <Text
              fontSize={DESIGN_CONFIG.typography.page.webMainSubtitle.fontSize}
              fontWeight={DESIGN_CONFIG.typography.page.webMainSubtitle.fontWeight}
              marginBottom={0}
              textColor={DESIGN_CONFIG.color.text.text200}
            >
              We would love to know more...
            </Text>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnMount
          validationSchema={schema}
        >
          {(formik) => (
            <Form>
              <div style={{ display: 'flex', flexDirection: 'column', margin: '0.75rem 0 1rem 0' }}>
                <Label htmlFor="comment">
                  Tell us what you think
                </Label>
                <StyledTextArea as="textarea" placeholder="Enter Your Feedback/Suggestion for Us.." type="text" id="comment" name="comment" />
              </div>
              <div style={{
                display: 'flex', gap: '.5rem', marginBottom: '1rem', flexDirection: `${MQ746 ? 'column' : 'row'}`,
              }}
              >
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Label htmlFor="firstName">First Name*</Label>
                  <StyledInput placeholder="Enter your First Name" type="text" id="firstName" name="firstName" />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Label htmlFor="lastName">Last Name*</Label>
                  <StyledInput placeholder="Enter your Last Name" type="text" id="lastName" name="lastName" />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                <Label htmlFor="email">Email*</Label>
                <StyledInput placeholder="Enter your Email" type="email" id="email" name="email" />
              </div>
              <Box display="flex" gap=".5rem" flexDirection={MQ746 ? 'column' : 'row'}>
                <div style={{
                  display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '1rem',
                }}
                >
                  <Label htmlFor="country">
                    Country
                  </Label>
                  <StyledInput placeholder="Enter your country" type="text" id="country" name="country" />
                </div>
                <div style={{
                  display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '1rem',
                }}
                >
                  <Label htmlFor="city">
                    City
                  </Label>
                  <StyledInput placeholder="Enter your city" type="text" id="city" name="city" />
                </div>
              </Box>
              <div style={{ display: 'flex', gap: '1.5rem' }}>
                <StyledButtonCancel onClick={handleModalClose} type="button">Close</StyledButtonCancel>
                <StyledButton
                  aria-disabled={!formik.isValid || formik.isSubmitting}
                  disabled={!formik.isValid || formik.isSubmitting}
                  tabIndex="-1"
                  type="submit"
                // todo: focus is not trapping while disabled
                >
                  {loading ? <Loader /> : 'Submit'}
                </StyledButton>
              </div>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </Overlay>
  );
}
