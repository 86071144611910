import { CircularProgress } from '@mui/material';
import React from 'react';
import Box from '../Box';

function Loader(props) {
  return (
    <Box {...props}>
      <CircularProgress style={{ color: 'white', height: 24, width: 24 }} />
    </Box>
  );
}

export default Loader;
