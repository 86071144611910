/* eslint-disable react/jsx-props-no-spreading */
import Home from 'Pages/Home';
import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RestrictedRoute({ component: Component, ...rest }) {
  const authToken = useSelector(({ auth }) => auth.token);

  return (
    <Route
      {...rest}
      render={(props) => (authToken ? <Home /> : <Component {...props} />)}
    />
  );
}

export default RestrictedRoute;
