/* eslint-disable max-len */
import axios from 'axios';
import { ENDPOINTS } from './endpoints';

const API = axios.create({ baseURL: `${process.env.REACT_APP_FRONTEND_URL}/user/` });

const deleteProfilePicture = (token) => API.delete(ENDPOINTS.delete_user_image, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const deleteUserAccount = () => API.delete(ENDPOINTS.delete_user_account);

const getUserIP = () => API.get(ENDPOINTS.get_user_ip);

const getUserReviews = (page, size, token) => API.get(ENDPOINTS.get_reviews_for_loggedin_user(page, size), {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const getProductsLikedByUser = (page, size, token) => API.get(ENDPOINTS.get_product_liked_for_loggedin_user(page, size), {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const gerUserProfileData = (page, size, token, username) => API.get(ENDPOINTS.get_profile_data_by_username(page, size, username), {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const followUser = (payload, token) => API.post(ENDPOINTS.follow_user, payload, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const reportUser = (payload, token) => API.post(ENDPOINTS.report_user, payload, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const updateOAuthMoreDetails = (payload) => API.put(ENDPOINTS.update_oauth_more_details, payload);

const uploadProfilePicture = (token, payload) => API.post(ENDPOINTS.upload_user_image, payload, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  },
});

export {
  deleteProfilePicture,
  deleteUserAccount,
  followUser,
  getUserIP,
  getUserReviews,
  getProductsLikedByUser,
  gerUserProfileData,
  reportUser,
  updateOAuthMoreDetails,
  uploadProfilePicture,
};
