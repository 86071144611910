import React from 'react';
import Box from 'ReusableComponents/Box';
import Text from 'ReusableComponents/Text';
import Button from 'ReusableComponents/Button';
import { Grid } from '@material-ui/core';
import TopbarSmall from 'Pages/ProductDetails/Topbar/TopbarSmall';
import TopBar from 'Pages/ProductDetails/Topbar';
import { useMedia } from 'helpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './style.css';
import { DESIGN_CONFIG } from 'utils';

function ErrorPages({
  title, subtitle, img, homeButtonNotAllowed,
}) {
  const smallDevice = useMedia('(max-width:450px)');

  return (
    <div className="top-page-container">
      {smallDevice ? <TopbarSmall /> : <TopBar />}
      <main className="error-page">
        <Box margin="auto">
          <Grid
            item
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            xs={12}
          >
            <Box
              alignItems="center"
              display="flex"
              h={smallDevice ? '21.875rem' : '31.25rem'}
              justifyContent="start"
              w={smallDevice ? '21.875rem' : '31.25rem'}
            >
              <LazyLoadImage
                alt={title}
                effect="blur"
                src={img}
                style={{
                  height: '100%',
                  width: '100%',
                  margin: '0 auto',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              maxW="31.25rem"
              padding={smallDevice && '0 1rem'}
              textAlign="center"
            >
              <Text
                fontSize={smallDevice ? '1.5rem' : '44px'}
                fontWeight="600"
                margin=".5rem 0"
              >
                {title}
              </Text>
              <Text
                fontSize={
                  smallDevice
                    ? '.75rem'
                    : '1.125rem'
                }
                fontWeight={400}
              >
                {subtitle}
              </Text>
              {!homeButtonNotAllowed && (
                <Button
                  alignItems="center"
                  borderRadius="6px"
                  border="none"
                  background={DESIGN_CONFIG.color.primary.primaryMain}
                  cursor="pointer"
                  display="flex"
                  h="3rem"
                  justifyContent="center"
                  m="0px 10px 0px 0px"
                  w="8.063rem"
                  // eslint-disable-next-line no-return-assign
                  onClick={() => (window.location.href = '/')}
                >
                  <Text
                    fontSize=".875rem"
                    fontWeight={500}
                    marginBottom="0px"
                    textColor={DESIGN_CONFIG.color.neutral.neutral500}
                  >
                    Go to home
                  </Text>
                </Button>
              )}
            </Box>
            <Box h="4rem" />
          </Grid>
        </Box>
      </main>
    </div>

  );
}

export default ErrorPages;
