/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import StyledLink from 'ReusableComponents/Link';
import Text from 'ReusableComponents/Text';
import { useMedia } from 'helpers';
import Box from '../../ReusableComponents/Box';
import './style.css';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';

function Footer() {
  const smallDevice = useMedia('(max-width:500px)');
  const menuData = [
    {
      menuTitle: STRING_CONFIG.inPage.common.txtHeaderReviewDale,
      links: [
        { title: STRING_CONFIG.inPage.common.lnkNavBarHome, url: '/' },
        { title: STRING_CONFIG.inPage.common.lnkFooterAbout, url: '/about-reviewdale' },
        { title: STRING_CONFIG.inPage.common.lnkFooterHelpSupport, url: '/help' },
      ],
    },
    {
      menuTitle: STRING_CONFIG.inPage.common.txtFooterContactUs,
      links: [
        { title: STRING_CONFIG.inPage.common.lnkFooterEmail, url: '/', subTitle: 'Email id' },
      ],
      showSocialIcons: true,
    },
  ];
  return (
    <footer className="footer">
      <Box
        display="flex"
        gap=".75rem"
        margin={smallDevice ? '2.5rem 0 0 0' : '2.5rem 0 1rem 0'}
      >
        <img
          alt="Reviewdale Logo"
          height={48}
          width={48}
          src="/assets/rdLogoWhite.svg"
        />
        <Box>
          <h1 style={{
            color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
            fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`,
            fontWeight: DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight,
            margin: '0',
          }}
          >
            {STRING_CONFIG.inPage.common.txtHeaderReviewDale}
          </h1>
          <p style={{
            fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`,
            fontWeight: DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight,
            color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
            margin: '0',
          }}
          >
            {STRING_CONFIG.inPage.common.txtHeaderReviewDaleTagline}
          </p>
        </Box>
      </Box>
      <Box
        display={smallDevice && 'flex'}
        justifyContent={smallDevice && 'space-between'}
      >
        {menuData.map((menu, index) => (
          <Box key={index}>
            <h2
              style={{
                color: `${DESIGN_CONFIG.color.primary.primary100}`,
                fontSize: `${smallDevice ? '1rem' : DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                marginTop: '1.5rem',
              }} e
            >
              {menu.menuTitle}
            </h2>
            <Box
              display="flex"
              flexDirection={smallDevice && 'column'}
              flexWrap="wrap"
              gap={smallDevice ? '1rem' : '1.5rem'}
            >
              {menu.links.map((link, i) => (
                <>
                  {smallDevice && link.subTitle
                    && (
                      <h3
                        style={{
                          color: `${DESIGN_CONFIG.color.primary.primary200}`,
                          fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`,
                          fontWeight: DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight,
                          marginBottom: '0',
                        }}
                      >
                        {link.subTitle}
                      </h3>
                    )}
                  <Link
                    style={{
                      color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                      fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                    }}
                    key={i}
                    to={link.url}
                  >
                    {link.title}
                  </Link>
                </>
              ))}
              {
                menu.showSocialIcons && smallDevice ? (
                  <Box marginTop={smallDevice ? '0' : '2.5rem'}>
                    <p style={{
                      color: `${DESIGN_CONFIG.color.primary.primary100}`,
                      fontSize: '.875rem',
                      fontWeight: 400,
                      marginBottom: `${smallDevice && '.5rem'}`,
                    }}
                    >
                      {STRING_CONFIG.inPage.common.txtFooterFollow}
                    </p>
                    <Box
                      display="flex"
                      gap="1rem"
                      marginTop=".5rem"
                    >
                      <a href="https://www.facebook.com/people/ReviewDale/61557662202365/"><img src="/assets/facebook.svg" alt="facebook" /></a>
                      <a href="https://twitter.com/reviewdale"><img src="/assets/XLogo.svg" alt="X" /></a>
                      <a href="https://www.instagram.com/reviewdale_/"><img src="/icons/instagram.svg" alt="linkedin" /></a>
                      <a href="https://www.linkedin.com/company/reviewdale"><img src="/assets/linkedin.svg" alt="linkedin" /></a>
                    </Box>
                  </Box>
                ) : null
              }
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        marginTop="2.5rem"
        display={smallDevice && 'none'}
      >
        <p
          style={{
            color: `${DESIGN_CONFIG.color.primary.primary100}`,
            fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
            fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
          }}
        >
          {STRING_CONFIG.inPage.common.txtFooterFollow}
        </p>
        <Box
          display="flex"
          gap="1rem"
          marginTop=".5rem"
        >
          <a href="https://www.facebook.com/people/ReviewDale/61557662202365/"><img src="/assets/facebook.svg" alt="facebook" /></a>
          <a href="https://twitter.com/reviewdale"><img src="/assets/XLogo.svg" alt="X" /></a>
          <a href="https://www.instagram.com/reviewdale_/"><img src="/icons/instagram.svg" alt="linkedin" /></a>
          <a href="https://www.linkedin.com/company/reviewdale"><img src="/assets/linkedin.svg" alt="linkedin" /></a>
        </Box>
      </Box>
      <Box
        alignItems="center"
        bt={`1px solid ${DESIGN_CONFIG.color.primary.primary200}`}
        display="flex"
        flexDirection={smallDevice && 'column'}
        gap={smallDevice && '.5rem'}
        justifyContent="space-between"
        marginTop={smallDevice ? '1.5rem' : '2.5rem'}
        padding="1.5rem 0 2.5rem 0"
      >
        <Box
          display="flex"
        >
          <StyledLink
            color={DESIGN_CONFIG.color.neutral.neutral500}
            fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
            fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
            to="/terms-of-service"
          >
            {STRING_CONFIG.inPage.common.lnkFooterTermsOfUse}
          </StyledLink>
          <Text
            fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
            fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
            margin="0 2px"
            textColor={DESIGN_CONFIG.color.neutral.neutral500}
          >
            •
          </Text>
          <StyledLink
            color={DESIGN_CONFIG.color.neutral.neutral500}
            fontSize={smallDevice ? '.875rem' : `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`}
            fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
            to="/privacy-policy"
          >
            {STRING_CONFIG.inPage.common.lnkFooterPrivacyPolicy}
          </StyledLink>
          <Text
            fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
            fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
            margin="0 2px"
            textColor={DESIGN_CONFIG.color.neutral.neutral500}
          >
            •
          </Text>
          <StyledLink
            color={DESIGN_CONFIG.color.neutral.neutral500}
            fontSize={smallDevice ? '.875rem' : `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`}
            fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
            to="/community-guidelines"
          >
            {STRING_CONFIG.inPage.common.lnkFooterCommunityGuidelines}
          </StyledLink>
        </Box>
        <Text
          fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
          fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
          margin="0"
          textColor={DESIGN_CONFIG.color.neutral.neutral500}
        >
          {STRING_CONFIG.inPage.common.txtFooterCopyright}
        </Text>
      </Box>
    </footer >
  );
}

export default Footer;
