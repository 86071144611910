/* eslint-disable max-len */
import axios from 'axios';
import { ENDPOINTS } from './endpoints';

const API = axios.create({ baseURL: `${process.env.REACT_APP_FRONTEND_URL}/platform/` });

const addReactionOnArticle = (articleId, reactionValue, token) => API.post(ENDPOINTS.add_reaction_on_article(articleId, reactionValue), {}, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const addCommentOnArticle = (articleId, payload, token) => API.post(ENDPOINTS.add_commentOnArticle(articleId), payload, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const initiateTransaction = (payload, token) => API.post(ENDPOINTS.create_order, payload, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const buildPlatformConfig = () => API.get(ENDPOINTS.get_platform_config);
const getArticle = (articleId) => API.get(ENDPOINTS.get_article(articleId));
const getCommentsforArticle = (page, size, articleId) => API.get(ENDPOINTS.get_comments_for_article(page, size, articleId));
const getTransactions = (token) => API.get(ENDPOINTS.get_transaction_detail, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const platformSearch = (query) => API.get(ENDPOINTS.platform_search(query));
const platformSearchResults = (page, size, query, token) => API.get(ENDPOINTS.platform_search_results(page, size, query), {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
const userCampaign = (payload) => API.post(ENDPOINTS.add_user_campaign, payload);

export {
  addCommentOnArticle,
  addReactionOnArticle,
  buildPlatformConfig,
  getArticle,
  getCommentsforArticle,
  getTransactions,
  initiateTransaction,
  platformSearch,
  platformSearchResults,
  userCampaign,
};
