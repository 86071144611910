// Importing constants
import {
  PATHS,
  RECENTLY_VIEWED,
  DISPLAY_FEATURES,
  CONNECTIVITY_FEATURES,
  CAMERA_FEATURES,
  GENERAL_FEATURES,
  PERFORMANCE,
  REVIEWS,
  STORAGE,
  CATEGORY_TEXT,
  BUYING_TYPES,
  ATTRIBUTE_TYPES,
  PRODUCT_REVIEW_DATA,
  PRODUCT_SHARE_DATA,
  PRODUCTS_LIST,
  NEWS,
  ERROR_TYPES,
  LIST,
  POLICY,
  DESIGN_CONFIG,
  SETTING_CONFIG,
  STRING_CONFIG,
} from './constants';

export {
  PATHS,
  RECENTLY_VIEWED,
  DISPLAY_FEATURES,
  CONNECTIVITY_FEATURES,
  CAMERA_FEATURES,
  GENERAL_FEATURES,
  PERFORMANCE,
  REVIEWS,
  STORAGE,
  CATEGORY_TEXT,
  BUYING_TYPES,
  ATTRIBUTE_TYPES,
  PRODUCT_REVIEW_DATA,
  PRODUCT_SHARE_DATA,
  PRODUCTS_LIST,
  NEWS,
  ERROR_TYPES,
  LIST,
  POLICY,
  DESIGN_CONFIG,
  SETTING_CONFIG,
  STRING_CONFIG,
};
